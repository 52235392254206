@import "scss/_colors.scss", "scss/_mixins.scss", "scss/_constants.scss";

.footer {
  @include flex-div($direction: column);
  border-top: 1px solid $gray;
  padding: 1rem 2rem;
  gap: 1rem;

  @media (max-width: $small-breakpoint) {
    padding: 1rem;
  }

  .row {
    @include flex-div($align-items: flex-start);
    gap: 1rem;
    width: 100%;

    .col {
      @include flex-div($direction: column);
      flex: 1;
      text-align: left;

      @media (max-width: $small-breakpoint) {
        text-align: center;
      }

      .heading {
        color: $primary-font-color;
        width: 100%;
        font-size: 1rem;
      }

      .content {
        width: 100%;

        p, span {
          color: $light-font-color;
          display: block;
        }

        span {
          font-weight: bold;
          margin: 0 0 0.5rem;
          text-transform: uppercase;
        }

        p {
          font-size: 0.9rem;
          margin: 0 0 0.4rem;
        }
      }
    }

    .colf2 {
      flex: 2;
    }

    .colRR, .colRL {
      @include flex-div;
      flex: 1;
      gap: 1rem;
    }

    .colRR {
      justify-content: flex-end;
      margin: 0 2rem 0 0;
      @media (max-width: $small-breakpoint) {
        margin: 0;
      }
    }

    .colRL {
      justify-content: flex-start;
      margin: 0 0 0 2rem;
      @media (max-width: $small-breakpoint) {
        margin: 0;
      }

      img {
        height: 40px;
      }
    }
  }

  .row3 {
    border-top: 1px solid $gray;
    text-transform: capitalize;

    @media (max-width: $small-breakpoint) {
      font-size: 0.8rem;
    }
  }

  a {
    color: $light-font-color;
    display: block;
    text-decoration: none;
    font-weight: bold;
    font-size: 0.9rem;
    padding: 0.2rem 0;
  }

  a:hover {
    color: $primary-font-color;
  }


}