@import "src/scss/_mixins.scss", "src/scss/_colors.scss", "scss/_constants.scss";

.cart-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;

  .item-image {

    img {
      height: 7rem;
      width: auto;
      object-fit: contain;

      @media (max-width: $small-breakpoint) {
        height: 5rem;
      }
    }
  }

  .item-detail {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;

    @media (max-width: $small-breakpoint) {
      gap: 0.1rem;
    }

    .item-name {
      font-size: 1.2rem;
      @media (max-width: $small-breakpoint) {
        font-size: 1rem;
      }
    }

    .item-price {
      font-weight: 700;
      @media (max-width: $small-breakpoint) {
        font-size: 0.8rem;
      }
    }

    .item-quantity {
      display: flex;
      gap: 0.1rem;
      justify-content: center;
      align-items: center;
      @media (max-width: $small-breakpoint) {
        font-size: 0.8rem;

        input {
          padding: 0.2rem 0.5rem;
        }
      }
    }
  }
}
