@import "src/scss/_mixins.scss", "src/scss/_colors.scss";

.custom-pagination {
  margin: 10px;

  .active {
    .page-link {
      border-color: $primary-btn-bg !important;
      background-color: $primary-btn-bg !important;
      color: $primary-btn-font !important;
    }
  }

  .page-link {
    color: $primary-btn-bg !important;
  }
}