@import "scss/_mixins.scss";

.custom-rating {
  @include flex-div($justify-content: flex-start, $gap: 0.5rem);

  .rating-value {
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: 6px;
  }
}