@import "scss/_mixins.scss", "scss/_colors.scss", "scss/_constants.scss";

.product-card {
  @include flex-div($direction: column, $gap: 0.4rem);
  height: 100%;
  cursor: pointer;

  .pic-section {
    @include flex-div($justify-content: flex-start);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: fit-content;
    }

    .img-hover {
      display: none;
      transition: display 2s;
    }

    &:hover {
      .img-top {
        display: none;
      }

      .img-hover {
        display: block;
        animation: fade_in_show 1s ease-in-out both;
      }

      @keyframes fade_in_show {
        0% {
          opacity: 0.5;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }

  .detail-section {
    @include flex-div($direction: column, $align-items: flex-start, $gap: 0.3rem);
    width: 100%;

    .name, .price {
      color: $primary-font-color;
      font-size: 1.1rem;
      font-weight: bold;

      @media (max-width: $small-breakpoint) {
        font-size: 0.9rem;
      }
    }

    .color {
      font-size: 0.9rem;
      color: $light-font-color;

      @media (max-width: $small-breakpoint) {
        font-size: 0.8rem;
      }
    }

  }
}