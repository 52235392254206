@import "scss/_colors.scss", "scss/_mixins.scss";

.search-bar {
  @include flex-div;
  background: $very-light-gray;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: 0.5s height ease-in-out;
  top: 80px;
  right: 0;
  position: absolute;
  z-index: 10;


  .search-form {
    @include flex-div;
    width: 100%;
    padding: 0 5%;
    gap: 10px;


    input[type='text'] {
      flex: 1;
      font-size: 1.2rem;
    }
  }
}

.search-bar.show {
  height: 50px;
}
