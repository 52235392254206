@import "src/scss/_mixins.scss", "src/scss/_colors.scss", "scss/_constants.scss";

.cart-wrapper {
  display: none;
  justify-content: space-between;
  align-items: center;

  &.show-cart {
    display: flex;
  }

  .cart-overlay {
    position: fixed;
    background: #ced4da;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.4;
  }
}

.cart {
  height: 100%;
  width: 30%;
  min-width: 25rem;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: $primary-btn-font;
  z-index: 2;
  border-left: 1px solid $gray;

  @media (max-width: $ms-breakpoint) {
    width: 70%;
    min-width: unset;
  }

  @media (max-width: $small-breakpoint) {
    width: 100%;
  }

  .help-text {
    color: $light-font-color;
    font-size: 14px;
  }

  > div {
    width: 100%;
  }

  .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 1rem;

    border-bottom: 1px solid $gray;

    @media (max-width: $small-breakpoint) {
      svg {
        width: 18px;
      }
    }

    span {
      font-size: 1.2rem;
      font-weight: 700;
      letter-spacing: 1px;

      @media (max-width: $small-breakpoint) {
        font-size: 1rem;
      }
    }
  }


  .cart-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    gap: 1.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }


  .cart-footer {
    align-self: flex-end;
    padding: 1.5rem 1rem;
    border-top: 1px solid $gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    button {
      width: 100%;
    }
  }
}