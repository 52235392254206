@import "scss/_colors.scss", "scss/_constants.scss";

.custom-collapse {
  border-bottom: 1px solid $gray;
  border-top: 1px solid $gray;

  .collapse-btn {
    background: none;
    color: $primary-font-color;
    border-radius: 0;
    border: none;
    text-transform: uppercase;
    text-align: left;
    padding: 0.8rem 0;

    @media (max-width: $small-breakpoint) {
      font-size: 0.8rem;
    }
  }

  .collapse {
    padding-bottom: 0.9rem;

    @media (max-width: $small-breakpoint) {
      font-size: 0.8rem;
    }
  }

}