@import "scss/_mixins.scss", "scss/_colors.scss";

.about-us {
  @include flex-div($direction: column, $gap: 2rem);
  margin-bottom: 2rem;
  padding: 2rem;
  color: $primary-font-color;

  .content-header {
    font-size: 28px;
  }

  .content-detail {

  }
}