@import "scss/_mixins.scss", "scss/_colors.scss";

.carousel {
  @include flex-div($direction: column, $justify-content: flex-start, $gap: 0.5rem);
  width: 100%;

  .thumbnail-container {
    @include grid-div(repeat(4, 1fr), $gap: 0.5rem);
    .thumbnail {
      width: 100%;
      height: auto;

    }

    .thumbnail.active {
      border: 1px solid $light-font-color;
      filter: brightness(60%);
      box-sizing: border-box;
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box;
    }
  }


  .main-image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .main-image {
      width: 100%;
      height: auto;
    }
  }
}