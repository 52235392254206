@import "scss/_colors.scss", "scss/_constants.scss";

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid $gray;

  .left-part, .right-part {
    flex: 1;
  }

  .left-part {
    text-align: left;
    padding: 0 0 0 3rem;

    @media (max-width: $medium-breakpoint) {
      padding: 0 0 0 2rem;
    }

    @media (max-width: $medium-breakpoint) {
      padding: 0 0 0 0.8rem;
    }

    .logo {
      height: 42px;
      cursor: pointer;

      @media (max-width: $medium-breakpoint) {
        height: 32px;
      }

      @media (max-width: $small-breakpoint) {
        height: 28px;
      }
    }
  }

  .center-part {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1.4rem;
    font: 1.2rem Helvetica, 'Helvetica Neue', Arial, 'Lucida Grande', sans-serif;
    text-transform: uppercase;

    @media (max-width: $medium-breakpoint) {
      gap: 1rem;
      font-size: 1rem;
    }

    @media (max-width: $small-breakpoint) {
      gap: 0.6rem;
      font-size: 0.7rem;
    }
  }

  .right-part {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    gap: 20px;
    padding: 0 3rem 0 0;

    button {
      padding: 0;
    }

    @media (max-width: $medium-breakpoint) {
      padding: 0 2rem 0 0;

      svg {
        width: 22px;
      }
    }

    @media (max-width: $small-breakpoint) {
      padding: 0 0.8rem 0 0;

      svg {
        width: 18px;
      }
    }
  }
}