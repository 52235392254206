@import "scss/_colors.scss";

@mixin flex-div($direction: row, $justify-content: center, $align-items: center, $gap: 0px) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
  gap: $gap;
}

@mixin grid-div($grid-template-columns: repeat(5, 1fr), $gap: 0px) {
  display: grid;
  grid-template-columns: $grid-template-columns;
  gap: $gap;
}

@mixin border-bottom($color: $gray) {
  border-bottom: 1px solid $color;
}

@mixin border-top($color: $gray) {
  border-top: 1px solid $color;
}


