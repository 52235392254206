@import "scss/_mixins.scss", "scss/_colors.scss", "scss/_constants.scss";

.checkout {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $medium-breakpoint) {
    flex-direction: column-reverse;
  }

  > div {
    width: 100%;
    height: 100%;
  }

  .checkout-contact {
    flex: 1;
    border-right: 1px solid $gray;
    padding: 3rem 2rem 3rem 5%;

    @media (max-width: $medium-breakpoint) {
      padding: 3rem 5%;
    }

    .contact-form {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;

      .form-head {
        font-size: 20px;
        font-weight: bold;
      }

      > div {
        width: 100%;
      }

      input:not([type="checkbox"]) {
        width: 100%;
      }

      .form-row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        > div {
          width: 100%;
        }
      }

      button {
        width: 100%;
      }

      .cash-on-delivery {

        input[type='radio'] {
          width: 1rem;
          height: 1rem;
          margin-bottom: 4px;
        }

        label {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .checkout-detail {
    flex: 1;
    padding: 3rem 5% 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;

    @media (max-width: $medium-breakpoint) {
      padding: 3rem 5% 0;
    }

    > div {
      width: 100%;
      height: 100%;
    }

    .checkout-items {
      max-height: 20rem;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;

      > div {
        width: 100%;
        height: 100%;
      }
    }

    .calculation {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;

      > div {
        width: 100%;
        height: 100%;
      }

      .calculation-minor {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        color: $light-font-color;
        font-size: 18px;

        .cal-head {

        }

        .cal-val {

        }
      }

      .calculation-major {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 20px;
        margin: 0.5rem 0 0 0;

        .cal-head {

        }

        .cal-val {

        }
      }
    }
  }
}