@import "scss/_mixins.scss", "scss/_colors.scss", "scss/_constants.scss";

.detail {
  @include flex-div($direction: column, $gap: 3rem);
  margin: 3rem 0;

  .product-detail {
    @include grid-div($grid-template-columns: 58% 38%, $gap: 4%);

    margin: 0 5rem;
    min-height: 40rem;

    @media (max-width: $lm-breakpoint) {
      margin: 0 2rem;
    }

    @media (max-width: $medium-breakpoint) {
      @include grid-div($grid-template-columns: repeat(1, 1fr), $gap: 4%);
      margin: 0 10% 2rem;
    }

    .detail-section {
      @include flex-div($direction: column, $justify-content: flex-start, $align-items: flex-start, $gap: 2rem);

      div {
        width: 100%;
      }

      .main-detail {
        @include flex-div($direction: column, $justify-content: flex-start, $align-items: flex-start, $gap: 0.3rem);
        @include border-bottom;

        .name, .price {
          color: $primary-font-color;
          font-size: 1.5rem;
          font-weight: bold;

          @media (max-width: $medium-breakpoint) {
            font-size: 1.2rem;
          }

          @media (max-width: $small-breakpoint) {
            font-size: 1rem;
          }

        }

        .price {
          margin: 0.5rem 0;

          @media (max-width: $small-breakpoint) {
            margin: 0.2rem 0;
          }

        }

        .color {
          font-size: 1.2rem;
          color: $light-font-color;
        }
      }
    }

    .variant-section {
      @include border-bottom;

      .v-head {
        font-size: 1rem;
        font-weight: bold;
      }

      .variants {
        @include grid-div($grid-template-columns: repeat(auto-fill, minmax(15%, 1fr)), $gap: 0.5rem);
        padding: 1rem 0;

        span {
          text-align: center;
          border: 1px solid $gray;
          font-size: 1.2rem;
          padding: 0.5rem;
        }
      }
    }

    .atc-section {
      .animated-button-1 {
        width: 100%;
        padding: 0.8rem 0.5rem;
      }
    }
  }
}