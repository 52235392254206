@import "scss/_mixins.scss", "scss/_colors.scss";

.customer-reviews {
  width: 100%;
  height: 100%;

  .reviews-content {
    margin: 0 2%;
    padding: 2rem;
    border: 1px solid $gray;

    .write-section {
      padding: 0 0 1rem 0;
    }

    .review-section {
      .review {
        @include border-top;
        padding: 1rem 0;
        .comment-head {
          .name-initial {
            border-radius: 100%;
            background: $gray;
            width: 3rem;
            height: 3rem;
          }

          .rating-detail {
            h6 {
              font-weight: bolder;
            }
          }
        }

        .comment {

        }
      }
    }
  }
}