@import "scss/_mixins.scss", "scss/_colors.scss";

.bg-section {
  @include flex-div($justify-content: flex-start);
  position: relative;

  .bg-img {
    object-fit: cover;
    object-position: bottom;
    height: 100%;
    width: 100%;
  }

  .content {
    @include flex-div($direction: column, $align-items: flex-start, $gap: 1rem);
    position: absolute;
    margin-left: 10rem;
    color: $primary-btn-font;
    .small-text {
      font-size: 0.8rem;
    }

    .large-text {
      font-size: 1.8rem;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }

    a {
      margin: 0.8rem 0 0 0.8rem;
    }
  }
}