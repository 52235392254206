@import "scss/_mixins.scss", "scss/_colors.scss", "scss/_constants.scss";

.exchange-policy {
  @include flex-div($direction: column, $gap: 2rem);
  margin-bottom: 2rem;
  padding: 2rem;
  color: $primary-font-color;

  .content-header {
    font-size: 1.8rem;

    @media (max-width: $medium-breakpoint) {
      font-size: 1.6rem;
    }

    @media (max-width: $small-breakpoint) {
      font-size: 1.4rem;
    }
  }

  .content-detail {

  }
}