@import "scss/_mixins.scss", "scss/_constants.scss";

.shop {
  @include flex-div($direction: column, $gap: 2rem);
  margin-bottom: 2rem;
  padding: 2rem;


  @media (max-width: $small-breakpoint) {
    padding: 10%;
  }

  .shop-products {
    @include grid-div($gap: 1rem);

    @media (max-width: $lm-breakpoint) {
      @include grid-div($grid-template-columns: repeat(4, 1fr), $gap: 0.8rem);
    }

    @media (max-width: $medium-breakpoint) {
      @include grid-div($grid-template-columns: repeat(3, 1fr), $gap: 0.8rem);
    }

    @media (max-width: $ms-breakpoint) {
      @include grid-div($grid-template-columns: repeat(2, 1fr), $gap: 0.8rem);
    }

    @media (max-width: $small-breakpoint) {
      @include grid-div($grid-template-columns: repeat(1, 1fr), $gap: 1.5rem);
    }
  }
}