@import "scss/_colors.scss";

.animated-link-1 {
  position: relative;
  display: inline-block;
  color: $primary-font-color;
  transition: all 0.3s linear;
  font-weight: bold;

  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: -5px;
    left: 50%;
    width: 0;
    height: 2px;
    border-bottom: 2px solid $primary-font-color;
  }

  &::after {
    transition: all 0.2s linear;
  }

  &:hover::after {
    left: 0;
    width: 100%;
  }
}

.animated-link-2 {
  --offset: 10px;
  --border-size: 2px;
  text-align: center;
  font-size: 0.8rem;
  display: block;
  position: relative;
  padding: 1em 2em;
  appearance: none;
  border: 0;
  background: transparent;
  color: $primary-btn-font;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 0;
  box-shadow: inset 0 0 0 var(--border-size) currentcolor;
  transition: background .8s ease;

  &:hover {
    background: rgba(100, 0, 0, .03);
  }

  .horizontal,
  .vertical {
    position: absolute;
    top: var(--horizontal-offset, 0);
    right: var(--vertical-offset, 0);
    bottom: var(--horizontal-offset, 0);
    left: var(--vertical-offset, 0);
    transition: transform .8s ease;
    will-change: transform;

    &::before {
      content: '';
      position: absolute;
      border: inherit;
    }
  }

  .horizontal {
    --vertical-offset: calc(var(--offset) * -1);
    border-top: var(--border-size) solid currentcolor;
    border-bottom: var(--border-size) solid currentcolor;

    &::before {
      top: calc(var(--vertical-offset) - var(--border-size));
      bottom: calc(var(--vertical-offset) - var(--border-size));
      left: calc(var(--vertical-offset) * -1);
      right: calc(var(--vertical-offset) * -1);
    }
  }

  &:hover .horizontal {
    transform: scaleX(0);
  }

  .vertical {
    --horizontal-offset: calc(var(--offset) * -1);
    border-left: var(--border-size) solid currentcolor;
    border-right: var(--border-size) solid currentcolor;

    &::before {
      top: calc(var(--horizontal-offset) * -1);
      bottom: calc(var(--horizontal-offset) * -1);
      left: calc(var(--horizontal-offset) - var(--border-size));
      right: calc(var(--horizontal-offset) - var(--border-size));
    }
  }

  &:hover .vertical {
    transform: scaleY(0);
  }

}

.animated-button-1 {
  border: 1px solid $primary-font-color !important;
  background: $primary-btn-bg;
  border-radius: 0;
  text-transform: uppercase;
  transition: all .5s ease;

  &:hover {
    color: $primary-font-color;
    background-color: $primary-btn-font;
  }
}