@import "scss/_mixins.scss", "scss/_constants.scss";

.product-section {
  min-height: 15rem;
  width: 100%;
  @include flex-div($align-items: flex-start);


  div {
    width: 100%;
    height: 100%;
  }

  .content {
    @include flex-div($direction: column, $gap: 1rem);
    padding: 0 2%;

    @media (max-width: $small-breakpoint) {
      padding: 0 10%;
    }

    .content-header {
      @include flex-div($justify-content: space-between);

      .heading {
        font-size: 1.5rem;
        text-transform: uppercase;

        @media (max-width: $small-breakpoint) {
          font-size: 1.1rem;
        }
      }

      .view {
        font-size: 0.9rem;
        text-transform: uppercase;
        font-weight: bold;

        @media (max-width: $small-breakpoint) {
          font-size: 0.7rem;
        }
      }
    }

    .products {
      @include grid-div($gap: 1rem);

      @media (max-width: $lm-breakpoint) {
        @include grid-div($grid-template-columns: repeat(4, 1fr), $gap: 0.8rem);
      }

      @media (max-width: $medium-breakpoint) {
        @include grid-div($grid-template-columns: repeat(3, 1fr), $gap: 0.8rem);
      }

      @media (max-width: $ms-breakpoint) {
        @include grid-div($grid-template-columns: repeat(2, 1fr), $gap: 0.8rem);
      }

      @media (max-width: $small-breakpoint) {
        @include grid-div($grid-template-columns: repeat(1, 1fr), $gap: 1.5rem);
      }
    }
  }
}