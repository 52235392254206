@import "scss/_mixins.scss", "scss/_colors.scss", "scss/_constants.scss";

.contact-us {
  @include flex-div($direction: column, $gap: 2rem);
  margin-bottom: 2rem;
  padding: 2rem;
  color: $primary-font-color;

  @media (max-width: $small-breakpoint) {
    gap: 1rem;
  }

  .content-header {
    font-size: 1.8rem;

    @media (max-width: $medium-breakpoint) {
      font-size: 1.6rem;
    }

    @media (max-width: $small-breakpoint) {
      font-size: 1.4rem;
    }
  }

  .content-detail {
    .contact-form {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      gap: 1rem;

      .form-head {
        font-size: 1.2rem;
        font-weight: bold;

        @media (max-width: $small-breakpoint) {
          font-size: 1rem;
        }
      }

      > div {
        width: 100%;
      }

      input:not([type="checkbox"]) {
        width: 100%;
      }

      .form-row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        > div {
          width: 100%;
        }
      }

      button {
        width: 100%;
      }

      .cash-on-delivery {

        input[type='radio'] {
          width: 1rem;
          height: 1rem;
          margin-bottom: 4px;
        }

        label {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}