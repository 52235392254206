@import "src/scss/_mixins.scss", "src/scss/_colors.scss", "scss/_constants.scss";

.checkout-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  font-size: 14px;

  .item-image {

    img {
      height: 5rem;
      width: 5rem;
      object-fit: contain;
    }
  }

  .item-detail {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;

    .item-price {
      font-weight: 700;
    }

    .item-quantity {
      display: flex;
      gap: 0.1rem;
      justify-content: center;
      align-items: center;
    }
  }

  .item-total {
    font-size: 16px;
    font-weight: bold;
    //margin: 0 0 0 2rem;
    //
    //@media (max-width: $small-breakpoint) {
    //  margin: 0 0 0 0.5rem;
    //}
  }


}
